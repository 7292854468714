import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-row align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "fs-4 fw-bolder m-0"
}
const _hoisted_3 = {
  key: 1,
  class: "fs-4 fw-bolder m-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["me-3 svg-icon svg-icon-2x", [{ 'incomplete': !_ctx.isCompleted }]])
    }, [
      _createVNode(_component_inline_svg, {
        class: "svg-icon-xl",
        src: `${_ctx.originUrl}/assets/svg/icons/actions/${_ctx.isCompleted ? 'doubleChecked' : 'close'}.svg`
      }, null, 8, ["src"])
    ], 2),
    (_ctx.isIndexed)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.index + '. ' + _ctx.title), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.title), 1))
  ]))
}