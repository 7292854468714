
import { defineComponent } from "vue";
import Utilities from "@utilities/Utilities";

export default defineComponent({
    props: ['title', 'isCompleted', 'isIndexed', 'index'],
    name: "OnboardingStepsTitle",
    setup() {
        const originUrl = window.location.origin;

        return {
            originUrl,
            id: Utilities.getIdGenerator().generateV4(),
        };
    },
});
