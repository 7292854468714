
import router from "@frameworks/vue/router/clean";
import OnboardingInteractor from "@interactors/onboarding/Onboarding.interactor";
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import IntercomService from "@services/intercom/Intercom.service";
import Container from "typedi";
import { defineComponent, inject } from "vue";
import OnboardingStepsTitle from "./OnboardingStepsTitle.vue";

export default defineComponent({
    props: ['steps', 'stepsProgress', 'mapping', 'stateUpdate', 'lastStepCompletionButtonText'],
    name: "OnboardingSteps",
    components: { OnboardingStepsTitle },
    watch: {
        progress() {
            this.$nextTick(() => {
                this.initAccordion();
            });
        }
    },
    setup() {
        const originUrl = window.location.origin;
        return {
            originUrl,
        };
    },
    data() {
        let $mitt: any;
        const progress = this.stepsProgress;
        return {
            progress,
            $mitt,
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
        this.initAccordion();
    },
    methods: {
        async handleGuideCompletion() {
            let firstTimeSetupProgress, advancedGuideProgress;
            const subsection = RoutingInteractor.getCurrentSubsection(false, false, true);

            //this way of getting progress solves gql giving old progress
            if (subsection === "first-time-setup") {
                firstTimeSetupProgress = this.progress;
                advancedGuideProgress = await OnboardingInteractor.getAdvancedGuideProgress();
            } else if (subsection === "advanced-guide") {
                advancedGuideProgress = this.progress;
                firstTimeSetupProgress = await OnboardingInteractor.getFirstTimeSetupProgress();
            }


            const firstTimeSetupProgressKeys = Object.keys(firstTimeSetupProgress);
            const advancedGuideProgressKeys = Object.keys(advancedGuideProgress);

            let isFirstTimeSetupComplete = true, isAdvancedGuideComplete = true;

            for (const keyIndex in firstTimeSetupProgressKeys) {
                const key = firstTimeSetupProgressKeys[keyIndex];
                const step = firstTimeSetupProgress[key];
                if (!step) {
                    isFirstTimeSetupComplete = false;
                    break;
                }
            }

            for (const keyIndex in advancedGuideProgressKeys) {
                const key = advancedGuideProgressKeys[keyIndex];
                const step = advancedGuideProgress[key];
                if (!step) {
                    isAdvancedGuideComplete = false;
                    break;
                }
            }

            switch (isFirstTimeSetupComplete && isAdvancedGuideComplete) {
                case true:
                    router.push("/home/personal-homepage");
                    break;
                case false:
                    if (isFirstTimeSetupComplete && !isAdvancedGuideComplete) { router.push("/onboarding/advanced-guide"); break };
                    if (!isFirstTimeSetupComplete && isAdvancedGuideComplete) { router.push("/onboarding/first-time-setup"); break };
                    break;
            }
        },
        handleFinishingStep(stepBlock) {
            if (!this.steps[stepBlock].finishingStep) { return true };

            const progress = this.progress;
            delete progress.disableProgressBar;

            const progressKeys = Object.keys(progress);
            const totalSteps = progressKeys.length;
            let completedSteps = 0;
            for (const stepIndex in progressKeys) {
                const step = progress[progressKeys[stepIndex]];
                if (step) {
                    completedSteps++;
                }
            }

            if (totalSteps - 1 <= completedSteps) { return true };
            return false;
        },
        handleAction(action: string) {
            if (action === "showIntercom") {
                Container.get(IntercomService).showIntercom();
                return;
            }
        },
        initAccordion() {
            let x = 0;
            const mappingKeys = Object.keys(this.mapping);
            const progressKeysLength = mappingKeys.length - 1;

            for (const keyIndex in this.mapping) {
                const key = this.mapping[keyIndex];
                if (!this.progress[key]) {
                    if (document.getElementById('accordion-button-' + x)?.classList.contains('collapsed')) {
                        document.getElementById('accordion-button-' + x)?.click();
                        return;
                    }
                    return;
                }
                x++;
            }

            document.getElementById('accordion-button-' + (progressKeysLength))?.click();
            return;
        },
        handleAccordionButtonClick(idx: number): void {
            let x = 0;
            let completedSteps = 0;
            for (const key in this.progress) {
                if (this.progress[key]) {
                    completedSteps++;
                }
            }

            this.scrollToAccordion(completedSteps, idx);
            this.pauseAllVideos(idx, x);

            return;
        },
        scrollToAccordion(completedSteps: number, idx: number): void {
            if (completedSteps !== 0 && !document.getElementById('accordion-button-' + idx)?.classList.contains('collapsed')) {
                setTimeout(() => {
                    document.getElementById('accordion-item-top-' + idx)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                }, 400);
                //setTimeout bcuz you need to wait for the (step close & open) animations to end; also is required for scroll to work once finishingStep is shown
            }
            return;
        },
        pauseAllVideos(idx: number, x: number): void {
            for (const key in this.progress) {
                const iframe: any | HTMLIFrameElement = document.getElementById('home-youtube-iframe' + x);
                if (iframe === null) {
                    return;
                }
                iframe.contentWindow.postMessage(JSON.stringify({
                    event: 'command',
                    func: 'pauseVideo'
                }), '*');
                x++;
            }
            return;
        },
        pauseCurrentVideo(idx: number) {
            const iframe: any | HTMLIFrameElement = document.getElementById('home-youtube-iframe' + idx);
            if (iframe === null) {
                return;
            }
            iframe.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'pauseVideo'
            }), '*');

            return;
        },
        forceNextStep() {
            this.initAccordion();
            return;
        },
        getAreAllCompleted(): boolean {
            for (const key in this.progress) {
                if (!this.progress[key]) {
                    return false;
                }
            }
            return true;
        },
        getIsLastIncomplete(): boolean {
            const progress = this.progress;
            delete progress.disableProgressBar;

            let completed = 0;
            for (const key in progress) {
                if (this.progress[key]) {
                    completed++;
                }
            }
            if (Object.keys(progress).length - completed === 1) {
                return true;
            }
            return false;
        },
        async updateStepState(step: string): Promise<void> {
            this.progress[step] = 1;
            await this.stateUpdate(step);
            this.$mitt.emit('onboarding-step-update', this.progress);
            return;
        },
    },
});
