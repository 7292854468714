
import IntercomService from "@services/intercom/Intercom.service";
import Container from "typedi";
import { defineComponent } from "vue";

export default defineComponent({
    name: "OnboardingResourceGuide",
    setup() {
        const originUrl = window.location.origin;
        return {
            originUrl,
        };
    },
    methods: {
        showIntercom() {
            Container.get(IntercomService).showIntercom();
        }
    }
});
